/* ==========================================================================
   Variables
   ========================================================================== */

/*
   Typography
   ========================================================================== */

$doc-font-size              : 16 !default;

/* paragraph indention */
$paragraph-indent           : false !default; // true, false (default)
$indent-var                 : 1.3em !default;

/* system typefaces */
$serif                      : Georgia, Times, serif !default;
$sans-serif                 : -apple-system, ".SFNSText-Regular", "San Francisco", "Roboto", "Segoe UI", "Helvetica Neue", "Lucida Grande", Arial, sans-serif !default;
$monospace                  : Monaco, Consolas, "Lucida Console", monospace !default;

/* sans serif typefaces */
$sans-serif-narrow          : $sans-serif !default;
$helvetica                  : Helvetica, "Helvetica Neue", Arial, sans-serif !default;

/* serif typefaces */
$georgia                    : Georgia, serif !default;
$times                      : Times, serif !default;
$bodoni                     : "Bodoni MT", serif !default;
$calisto                    : "Calisto MT", serif !default;
$garamond                   : Garamond, serif !default;

$global-font-family         : $sans-serif !default;
$header-font-family         : $sans-serif !default;
$caption-font-family        : $serif !default;

/* type scale */
$type-size-1                : 2.441em !default;  // ~39.056px
$type-size-2                : 1.953em !default;  // ~31.248px
$type-size-3                : 1.563em !default;  // ~25.008px
$type-size-4                : 1.25em !default;   // ~20px
$type-size-5                : 1em !default;      // ~16px
$type-size-6                : 0.75em !default;   // ~12px
$type-size-7                : 0.6875em !default; // ~11px
$type-size-8                : 0.625em !default;  // ~10px


/*
   Colors
   ========================================================================== */

$gray                       : #7a8288 !default;
$dark-gray                  : mix(#000, $gray, 40%) !default;
$darker-gray                : mix(#000, $gray, 60%) !default;
$light-gray                 : mix(#fff, $gray, 50%) !default;
$lighter-gray               : mix(#fff, $gray, 90%) !default;

$body-color                 : #fff !default;
$background-color           : #fff !default;
$code-background-color      : #fafafa !default;
$code-background-color-dark : $light-gray !default;
$text-color                 : $dark-gray !default;
$border-color               : $lighter-gray !default;

$primary-color              : #7a8288 !default;
$success-color              : #62c462 !default;
$warning-color              : #f89406 !default;
$danger-color               : #ee5f5b !default;
$info-color                 : #52adc8 !default;

/* brands */
$behance-color              : #1769FF !default;
$bitbucket-color            : #205081 !default;
$dribbble-color             : #ea4c89 !default;
$facebook-color             : #3b5998 !default;
$flickr-color               : #ff0084 !default;
$foursquare-color           : #0072b1 !default;
$github-color               : #171516 !default;
$google-plus-color          : #dd4b39 !default;
$instagram-color            : #517fa4 !default;
$lastfm-color               : #d51007 !default;
$linkedin-color             : #007bb6 !default;
$pinterest-color            : #cb2027 !default;
$rss-color                  : #fa9b39 !default;
$soundcloud-color           : #ff3300 !default;
$stackoverflow-color        : #fe7a15 !default;
$tumblr-color               : #32506d !default;
$twitter-color              : #55acee !default;
$vimeo-color                : #1ab7ea !default;
$vine-color                 : #00bf8f !default;
$youtube-color              : #bb0000 !default;
$xing-color                 : #006567 !default;


/* links */
$link-color                 : $info-color !default;
$link-color-hover           : mix(#000, $link-color, 25%) !default;
$link-color-visited         : mix(#fff, $link-color, 25%) !default;
$masthead-link-color        : $primary-color !default;
$masthead-link-color-hover  : mix(#000, $primary-color, 25%) !default;
$navicon-link-color-hover   : mix(#fff, $primary-color, 75%) !default;

/*
   Breakpoints
   ========================================================================== */

$small                      : 600px !default;
$medium                     : 768px !default;
$medium-wide                : 900px !default;
$large                      : 1024px !default;
$x-large                    : 1280px !default;


/*
   Grid
   ========================================================================== */

$right-sidebar-width-narrow : 200px !default;
$right-sidebar-width        : 300px !default;
$right-sidebar-width-wide   : 400px !default;


/*
   Other
   ========================================================================== */

$border-radius              : 4px !default;
$box-shadow                 : 0 1px 1px rgba(0, 0, 0, 0.125) !default;
$navicon-width              : 1.5rem !default;
$navicon-height             : 0.25rem !default;
$global-transition          : all 0.2s ease-in-out !default;
$intro-transition           : intro 0.3s both !default;
